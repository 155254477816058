import React from 'react'
import Layout from '../components/layout';
import Helmet from "react-helmet"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const Home = ({ location }) => {
    return (
        <Layout location = {location}>
            <Helmet>
                <title>Careers - Vantage Circle </title>

                <meta name="description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <meta property="twitter:title" content="Careers - Vantage Circle" />
                        
                <meta property="twitter:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/careers/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Careers - Vantage Circle" />

                <meta property="og:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <link rel="canonical" href="https://www.vantagecircle.com/careers/"></link>
            </Helmet>
            <section className="bg-purple-50  mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-40 md:pb-10 relative">
                <div className="container max-w-5xl text-center px-6">
                    <h1 className="hero-title-2">
                        <span className="text-orange">Life</span> at Vantage Circle
                    </h1>
                    <p className="section-subtitle">
                        Life at Vantage Circle is fun and exciting. We believe in fostering a work environment where employees can upskill and grow. <br/> Our team is flexible and believes in teamwork, empowering each other, and being a tight-knit family. We believe in <br/> innovations to help and build company cultures that can transform the future of work. Join us on our journey.
                    </p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                        <div className="">
                            <a href="#career" className="vc-colored-btn">
                                Search Openings
                            </a>
                        </div>
                    </div>
                </div>
                <img loading="lazy" className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/07/Career-L1.png" width="200" height="200" alt="Career" />
                <img loading="lazy" className="absolute z-1 hero-iconsl2 hero-icons-bottom" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/07/Career-L2.png" width="150" height="150" alt="Career"/>
                <img loading="lazy" className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/07/Career-R1.png" width="200" height="200" alt="Career"/>
                <img loading="lazy" className="absolute z-1 hero-iconsr2 hero-icons-bottom" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/07/Career-R2.png" width="150" height="150" alt="Career"/>
            </section>
            <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div>
            <section className="py-40">
                <div className="container px-6">
                    <div className="text-center">
                        <h2 className="section-title">
                            <span className="text-orange">Become a Part</span> of our Vantage Circle Family
                        </h2>
                        <p className="section-subtitle">
                            If you are passionate about what you do, we'd love to be a part of that journey.
                        </p>
                    </div>
                    <div className="mt-20 grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 items-center">
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" x="0px" y="0px" viewBox="0 0 40 40">
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M33.9,21.3h-3c0.3,0.8,0.5,1.8,0.5,2.7v11.4c0,0.4-0.1,0.8-0.2,1.1h5c1.9,0,3.4-1.5,3.4-3.4v-6.2
                                                    C39.5,23.8,37,21.3,33.9,21.3z"></path>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M8.7,24c0-0.9,0.2-1.9,0.5-2.7h-3c-3.1,0-5.6,2.5-5.6,5.6v6.2c0,1.9,1.5,3.4,3.4,3.4h5
                                                    c-0.1-0.4-0.2-0.7-0.2-1.1V24z"></path>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M23.4,18.4h-6.9c-3.1,0-5.6,2.5-5.6,5.6v11.4c0,0.6,0.5,1.1,1.1,1.1H28c0.6,0,1.1-0.5,1.1-1.1V24
                                                    C29.1,20.9,26.6,18.4,23.4,18.4z"></path>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M20,3.5c-3.7,0-6.8,3-6.8,6.8c0,2.5,1.4,4.7,3.5,5.9c1,0.6,2.1,0.9,3.3,0.9s2.3-0.3,3.3-0.9
                                                    c2.1-1.2,3.5-3.4,3.5-5.9C26.8,6.5,23.7,3.5,20,3.5z"></path>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M8.1,9.8C5.3,9.8,3,12,3,14.8s2.3,5.1,5.1,5.1c0.7,0,1.4-0.1,2-0.4c1.1-0.5,1.9-1.3,2.5-2.3
                                                    c0.4-0.7,0.6-1.5,0.6-2.4C13.2,12,10.9,9.8,8.1,9.8z"></path>
                                            </g>
                                        </g>
                                        <g className="st0">
                                            <g>
                                                <path className="st1" d="M31.9,9.8c-2.8,0-5.1,2.3-5.1,5.1c0,0.9,0.2,1.7,0.6,2.4c0.5,1,1.4,1.8,2.5,2.3c0.6,0.3,1.3,0.4,2,0.4
                                                    c2.8,0,5.1-2.3,5.1-5.1S34.7,9.8,31.9,9.8z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                        Our Culture
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                We practice what we preach. We celebrate great work and recognize each other's potential to build a better culture together. 
                                </p>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" viewBox="0 0 40 40">
                                        <g className="st0">
                                            <path className="st1" d="M38.4,19.5H1.6c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h12.8c0.4,1.9,1.8,3.5,3.6,4.2l-6.3,8.1h-0.8
                                                c-1.9,0-3.4,1.5-3.4,3.4v0.8c0,0.6,0.5,1.1,1.1,1.1h22.9c0.6,0,1.1-0.5,1.1-1.1v-0.8c0-1.9-1.5-3.4-3.4-3.4h-0.8L22,26
                                                c1.8-0.7,3.2-2.3,3.6-4.2h12.8c0.6,0,1.1-0.5,1.1-1.1S39,19.5,38.4,19.5z M25.5,34.2H14.5l5.5-7L25.5,34.2z"></path>
                                            <path className="st1" d="M18.1,16.9c0-2.8-1.4-5.2-3.5-6.6c-1.2,1-2.8,1.7-4.5,1.7s-3.3-0.6-4.5-1.7c-2.1,1.4-3.5,3.9-3.5,6.6v0.4h16
                                                V16.9z"></path>
                                            <path className="st1" d="M24.6,17.3h10.7c1.5,0,2.7-1.2,2.7-2.7v-2.7h-16v2.7C21.9,16.1,23.1,17.3,24.6,17.3z"></path>
                                            <circle className="st1" cx="10.1" cy="5.1" r="4.6"></circle>
                                            <path className="st1" d="M37.9,7.7c0-1.5-1.2-2.7-2.7-2.7h-1.1V3.2c0-1.5-1.2-2.7-2.7-2.7h-3c-1.5,0-2.7,1.2-2.7,2.7v1.9h-1.1
                                                c-1.5,0-2.7,1.2-2.7,2.7v1.9h16V7.7z M31.8,5.1H28V3.2c0-0.2,0.2-0.4,0.4-0.4h3c0.2,0,0.4,0.2,0.4,0.4V5.1z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                    Work-life Balance
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                We take work-life balance seriously and believe in giving the employees the flexibility they need to perform better. 
                                </p>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" viewBox="0 0 100 100">
                                        <g className="st0">
                                            <path className="st1" d="M59.6,49.5c13.6,0,24.6-11,24.6-24.6S73.1,0.3,59.6,0.3S35,11.3,35,24.9S46,49.5,59.6,49.5z M56.7,10.4V9.8c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9v0.7c3.5,0.7,6.1,3.8,6.1,7.5c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
                                                c0-1-0.8-1.9-1.9-1.9h-2.6c-1,0-1.9,0.8-1.9,1.9c0,0.6,0.3,1.1,0.8,1.5l2.4,1.8l5.9,4.4c1.9,1.4,3.1,3.7,3.1,6.1v0c0,2.1-0.8,4-2.2,5.5c-1.1,1.1-2.4,1.8-3.9,2.1V40c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9v-0.7c-1.4-0.3-2.7-1-3.8-2
                                                c-1.5-1.4-2.3-3.4-2.3-5.4c0-1.6,1.3-2.9,2.9-2.9h0c1.6,0,2.9,1.3,2.9,2.9c0,1,0.8,1.9,1.9,1.9c1.6,0,1,0,2.6,0c1,0,1.9-0.8,1.9-1.9v0c0-0.6-0.3-1.1-0.8-1.5l-2.4-1.8l-5.9-4.4c-1.9-1.4-3.1-3.8-3.1-6.2C50.6,14.3,53.2,11.2,56.7,10.4z"></path>
                                            <path className="st1" d="M19.8,63.5c-0.7-1.3-2.4-1.7-3.7-1L2.4,70.4c-1.3,0.8-1.7,2.4-1,3.7l14,24.2c0.7,1.3,2.4,1.7,3.7,1l13.7-7.9c1.3-0.7,1.7-2.4,1-3.7L19.8,63.5z"></path>
                                            <path className="st1" d="M98.2,58.6c-1.3-1.8-3.9-2.2-5.7-0.9c-5.5,4-16.2,11.7-17,12.3c-0.4,0.3-0.8,0.6-1.2,0.9c-1.7,1.1-3.6,1.6-5.6,1.6H54.7c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9h14.9c2.2,0,3.9-1.8,3.9-4c-0.1-2.1-1.9-3.7-3.9-3.7H58.1c-0.8-0.8-1.6-1.6-2.5-2.2c-3.1-2.3-6.9-3.6-11.1-3.6c-7.4,0-14.7,4.7-17.8,10.9l11.9,20.6H61c4.4,0,8.7-1.1,12.5-3.3c1.3-0.8,2.7-1.7,4.3-2.8c6.4-4.6,19.5-14.2,19.5-14.2C99.1,63,99.5,60.5,98.2,58.6L98.2,58.6z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                    Compensation
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                We offer you a competitive compensation and benefits package, generous paid time off, and more to support you (and your family) in all aspects of your life.
                                </p>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" viewBox="0 0 100 100">
                                        <g className="st0">
                                            <path className="st1" d="M50,13.9C28.6-4.8,1.5,11.7,1,33.9c-0.1,5.7,1.5,11.2,4.8,16.3h21.1l3.8-6.3c1.1-1.8,3.7-1.9,4.9-0.1l7.9,12.5L55,31.9c1-2.1,4-2.2,5.1-0.1l9.9,18.4h24.1C112,22.3,76.7-9.5,50,13.9L50,13.9z"></path>
                                            <path className="st1" d="M65.8,54.4l-8-14.9l-11.3,24c-1,2-3.8,2.2-5,0.3l-8.2-12.9L31,54.5c-0.5,0.9-1.5,1.4-2.5,1.4H10.3c0.6,0.6-2.5-2.4,37.6,37.4c1.1,1.1,2.9,1.1,4,0c39.5-39.3,37-36.8,37.6-37.4H68.3C67.3,55.9,66.3,55.4,65.8,54.4L65.8,54.4z"></path>
                                        </g>
                                    </svg>

                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                    Wellness
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                The Vantage Circle family is there to support you in your journey. Our in-house fitness solution, <a href="https://www.vantagefit.io/">Vantage Fit</a>, helps you keep your fitness on track.
                                </p>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" viewBox="0 0 40 40">
                                        <g className="st0">
                                            <path id="XMLID_49_" className="st1" d="M24.6,35.2v1.2c0,0.9-0.7,1.7-1.6,1.9l-0.3,1.1c-0.2,0.6-0.7,1-1.3,1h-2.9c-0.6,0-1.1-0.4-1.3-1L17,38.2c-0.9-0.1-1.6-0.9-1.6-1.9v-1.2c0-0.6,0.5-1.1,1.1-1.1h7C24.1,34,24.6,34.6,24.6,35.2z M30,19.2c0,2.7-1.1,5.2-2.8,6.9c-1.3,1.4-2.2,3.1-2.5,5c-0.1,0.8-0.8,1.4-1.6,1.4h-6.2c-0.8,0-1.5-0.6-1.6-1.4c-0.3-1.9-1.2-3.6-2.5-5c-1.7-1.8-2.8-4.2-2.8-6.8c-0.1-5.6,4.4-10.1,9.9-10.1C25.5,9.1,30,13.6,30,19.2z M21.1,13.1c0-0.6-0.5-1.1-1.1-1.1c-4,0-7.2,3.2-7.2,7.2c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0-2.7,2.2-5,5-5C20.6,14.3,21.1,13.8,21.1,13.1z M20,5.9c0.6,0,1.1-0.5,1.1-1.1v-4c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1v4C18.9,5.4,19.4,5.9,20,5.9z M6.7,19.2c0-0.6-0.5-1.1-1.1-1.1h-4c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h4C6.2,20.3,6.7,19.8,6.7,19.2z M38.4,18.1h-4c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h4c0.6,0,1.1-0.5,1.1-1.1C39.5,18.6,39,18.1,38.4,18.1z M9,28.6l-2.8,2.8c-0.4,0.4-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l2.8-2.8c0.4-0.4,0.4-1.2,0-1.6C10.2,28.1,9.4,28.1,9,28.6z M30.2,10.1c0.3,0,0.6-0.1,0.8-0.3L33.8,7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0l-2.8,2.8c-0.4,0.4-0.4,1.2,0,1.6 C29.6,10,29.9,10.1,30.2,10.1z M9,9.8c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3c0.4-0.4,0.4-1.2,0-1.6L7.8,5.4c-0.4-0.4-1.2-0.4-1.6,0C5.8,5.8,5.8,6.5,6.2,7L9,9.8z M31,28.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6l2.8,2.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.4-0.4,0.4-1.2,0-1.6L31,28.6z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                    Innovation
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                Let's ignite your spark. If you have ideas that can bring a change; we are game. We acknowledge innovative ideas and mindsets. 
                                </p>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="flex pr-10 items-center">
                                <div className="p-3 w-12 h-12 rounded-lg shadow mb-4 flex-shrink-0">
                                    <svg className="svgicon svgicon1" viewBox="0 0 40 40">
                                        <g className="st0">
                                            <path className="st1" d="M17.5,15.1c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9C14.6,13.7,15.9,15.1,17.5,15.1z"></path>
                                            <path className="st1" d="M8,34.5v3.9c0,0.6,0.5,1.1,1.1,1.1h15.3c0.6,0,1.1-0.5,1.1-1.1v-3.8h4.3c1.7,0,3.1-1.4,3.1-3.1v-5.4l2.5,0c1,0,1.6-1.1,1.1-1.9l-2.7-5.1c-0.6-1.1-0.9-2.4-0.9-3.7l0,0l0,0C32.8,7,26.2,0.4,18,0.5C9.8,0.6,3.3,7.2,3.4,15.4c0,2.4,0.6,4.6,1.6,6.5C7,25.8,8,30.1,8,34.5z M9.7,11.6c0-0.3,0.2-0.5,0.4-0.6l1.6-0.7c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0,0,0-0.1c0.1-0.2,0.1-0.4,0-0.5l-0.7-1.6c-0.1-0.3,0-0.6,0.2-0.8l0.7-0.7c0.2-0.2,0.5-0.3,0.7-0.2l1.6,0.7c0.2,0.1,0.4,0.1,0.5,0c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.4-0.4l0.7-1.6c0.1-0.3,0.4-0.4,0.6-0.4h1c0.3,0,0.5,0.2,0.6,0.4l0.7,1.6c0.1,0.2,0.2,0.3,0.4,0.4c0,0,0,0,0,0c0.2,0.1,0.4,0.1,0.5,0l1.6-0.7c0.3-0.1,0.6,0,0.8,0.2L23.4,7c0.2,0.2,0.3,0.5,0.2,0.7l-0.7,1.6c-0.1,0.2-0.1,0.4,0,0.5c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.4,0.4l1.6,0.7c0.3,0.1,0.4,0.4,0.4,0.6v1c0,0.3-0.2,0.5-0.4,0.6L23.2,14c-0.2,0.1-0.3,0.2-0.4,0.4l0,0c-0.1,0.2-0.1,0.4,0,0.5l0.7,1.6c0.1,0.3,0,0.6-0.2,0.8L22.7,18c-0.2,0.2-0.5,0.3-0.7,0.2l-1.6-0.7c-0.2-0.1-0.4-0.1-0.5,0c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.4,0.4l-0.7,1.6c-0.1,0.3-0.4,0.4-0.6,0.4h-1c-0.3,0-0.5-0.2-0.6-0.4l-0.7-1.6c-0.1-0.2-0.2-0.3-0.4-0.4c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.5,0l-1.6,0.7c-0.3,0.1-0.6,0-0.8-0.2l-0.7-0.7c-0.2-0.2-0.3-0.5-0.2-0.7l0.7-1.6c0.1-0.2,0.1-0.4,0-0.5c0,0,0,0,0-0.1C12,14.2,11.9,14,11.7,14l-1.6-0.7c-0.3-0.1-0.4-0.4-0.4-0.6L9.7,11.6L9.7,11.6L9.7,11.6z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-4">
                                    <h3 className="box-title-1">
                                    Learning
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <p className="box-subtitle-1">
                                Join our agile learning community to grow your skill sets. Make faster and better-informed decisions, stretch your thinking horizons with diverse people. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container max-w-7xl mx-auto pb-20 md:pb-40 px-6">
                <h2 className="section-title text-center">
                    Sneak-Peek into our <span className="text-orange">Company Culture</span>
                </h2>
                <p className="section-subtitle text-center">
                We are on a quest to build a positive work culture.
                </p>
                <div className="max-w-3xl mx-auto mt-10 modal-open text-center modal-toggle">
                    <div className="img-overlay cursor-pointer  relative">
                        <img loading="lazy" className="mb-5 mx-auto rounded-lg shadow border-8 border-white" src="https://img.youtube.com/vi/qPUI4H0nCLo/maxresdefault.jpg" alt="Vantage Circle Video"/>
                        <div className="iconplay d-flex align_items_center">
                            <svg className="w-10 h-10 md:w-32 md:h-32 top-0 bg-white rounded-full shadow" viewBox="-1 1 24 22" width="24" height="24"><polygon fill="#654AB7" points="15 12 9 16 9 8"></polygon></svg>
                        </div>
                    </div>
                </div>

                <div className="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                    <div className="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <button className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                            <svg className="fill-current text-white" width="30" height="30" viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                            </svg>
                        </button>
                        <div className="modal-content text-left">
                            <div className="aspect-w-16 aspect-h-9">
                                <iframe className="video" src="https://www.youtube.com/embed/qPUI4H0nCLo" title="Vantage Circle Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>

            <section id="career" className="md:pb-40">
                <div className="container max-w-7xl px-6">
                    <div className="text-center">
                        <h2 className="section-title">
                            Are You the One We’re Looking For?
                        </h2>
                        <p className="section-subtitle">
                            View our current job openings.
                        </p>
                        <iframe title="Kekahire" src="https://vantagecircle.kekahire.com/api/embedjobs/872abe36-d59b-41c6-8ec0-697f3b55936e" frameborder="0" height="530px" width="100%"></iframe>
                    </div>
                </div>
            </section>
            <section className="md:pb-40">
                <div className="container max-w-7xl px-6">
                    <div className="text-center">
                        <h2 className="section-title">
                        The <span className="text-orange">Hiring</span> Process
                        </h2>
                        <p className="section-subtitle">
                            Take the route to join us.
                        </p>
                        <div className="py-10 grid grid-cols-1 gap-9 md:grid-cols-5">
                            <div className="steps relative">
                                <div className="h-20 w-20 rounded-full shadow text-center mb-10 font-bold text-xl md:text-2xl text-purple-200 flex justify-center items-center mx-auto">1</div>
                                <p className="text-center">Apply through the portal</p>
                            </div>
                            <div className="steps relative">
                                <div className="h-20 w-20 rounded-full shadow text-center mb-10 font-bold text-xl md:text-2xl text-purple-200 flex justify-center items-center mx-auto">2</div>
                                <p className="text-center">Assignment or Questionnaire</p>
                            </div>
                            <div className="steps relative">
                                <div className="h-20 w-20 rounded-full shadow text-center mb-10 font-bold text-xl md:text-2xl text-purple-200 flex justify-center items-center mx-auto">3</div>
                                <p className="text-center">HR Round</p>
                            </div>
                            <div className="steps relative">
                                <div className="h-20 w-20 rounded-full shadow text-center mb-10 font-bold text-xl md:text-2xl text-purple-200 flex justify-center items-center mx-auto">4</div>
                                <p className="text-center">Domain Round</p>
                            </div>
                            <div className="relative">
                                <div className="h-20 w-20 rounded-full shadow text-center mb-10 font-bold text-xl md:text-2xl text-purple-200 flex justify-center items-center mx-auto">5</div>
                                <p className="text-center">Final Round</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container max-w-4xl mx-auto px-6 md:pb-40">
                <div className="text-center pb-5 md:pb-14">
                    <h2 className="section-title">
                        Frequently Asked Questions
                    </h2>
                </div>
                <div className="career-faq bg-purple-50 p-5 md:p-14">
                   <Accordion preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                1.&nbsp;How can I apply for any job profiles in Vantage Circle?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Thanks for asking, to apply with us, you need to visit our careers page for relevant profiles. If anyone of the listed job profile is of your interest or relevant to your skills and experience, you can apply by clicking on the <strong>APPLY FOR THIS JOB</strong> button after going through the Job Description.</p>
                                <p className="mb-0">Upon clicking on the <strong>APPLY FOR THIS JOB</strong> button, you will be redirected to our career portal. You will have to fill out the application form with your details and attach the resume or cover letter.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    2.&nbsp;Can I apply for more than one job?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">Yes, you can apply for more than one job but we would advise you to apply to a similar or relevant job profile.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    3.&nbsp;Why can’t I apply for the same job profile twice?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>You cannot apply twice for the same profile using the same email address and phone number. However, if you had an issue with the first application, you can apply again but with a different email address and phone number.</p>
                                <p className="mb-0">We do suggest that you apply once and recheck your application before submitting it to ensure a smooth process.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    4.&nbsp;What happens after I submit my application?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>After you submit your application, the recruiting team will be checking the resumes, and based on the relevance with the job role, your application will be processed.</p>
                                <p className="mb-0">If shortlisted, you will receive an email from us to continue for further process.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    5.&nbsp;How much time does it take to process the applications?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">Our recruiters are trying their best to ensure a smooth and fast process. However, due to the large volume of applications and multiple openings in our organization, it might take up to 30 to 45 days to process the application.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    6.&nbsp;How will I know if my application is shortlisted or rejected?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Once you submit your application, the process of shortlisting is started. The candidates who are shortlisted will receive a call or an email from our end. You will be given certain tasks or called for an interview based on which you will be assessed for further process.</p>
                                <p>If your application is rejected, you will be receiving an email from our career portal <strong>no-reply@email.keka-mail.com</strong> mentioning the same.</p>
                                <p className="mb-0">However, sometimes our mails can be forwarded to the <strong>Spam</strong> section, so kindly check your emails regularly after you submit your application.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    7.&nbsp;Is remote working available in Vantage Circle?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">Currently, due to the COVID scenario in the country, our employees are working remotely. However, we don’t offer permanent remote working.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    8.&nbsp;How will I know the reason for rejecting my application?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Glad, you asked that. The applications are rejected based on the criteria that are set for the candidate we are looking for. </p>
                                <p>Your application can be rejected if your profile is not relevant to the job description, or you don't meet the given criteria required for the profile.</p>
                                <p className="mb-0">Also, the applications are rejected if you submit an incomplete profile or did not submit the resume.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    9.&nbsp;Can I refer someone?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Yes we do welcome referrals. We would suggest that the referred candidate himself or herself will have to apply from their own email id and contact number with the resume on our career portal.</p>
                                <p>Also, the candidate can mention the name of the individual who has referred him/her to apply in Vantage Circle, while filling the application form.</p>
                                <p className="mb-0">Referral can also be any employee of Vantage Circle with whom you are acquainted with.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    10.&nbsp;How will I be updated about the jobs in Vantage Circle?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>To stay updated about jobs in our organization, you need to check our <a href="/careers/">Careers</a> page regularly. You can also stay updated about new profiles by following our <a href="https://www.linkedin.com/company/vantage-circle/">LinkedIn</a> , <a href="https://www.instagram.com/vantagecircle/">Instagram</a> or <a href="https://www.facebook.com/vantagecircle">Facebook</a> page.</p>
                                <p className="mb-0">Also, you can check other job portals like Assam Careers, sakori.org, Naukri, Yuvajobs etc.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion> 
                </div>
            </section>
        </Layout>
    )
}

export default Home